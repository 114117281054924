<template>
    <div class="relative">
        <input
            :placeholder="placeholder"
            type="text"
            class="min-w-full max-w-[250px] text-sm px-2 py-1 focus:outline-none focus:border-gray-400"
            :class="{ 'border rounded-md': !unStyled }"
            ref="searchInput"
            @input="autocomplete"
            @blur="handleBlur"
            @focus="handleFocus"
            autocapitalize="off"
            autocorrect="off"
            autocomplete="off"
            spellcheck="false"
            v-validate="required ? 'required' : ''"
            data-vv-delay="1000"
            name="search"
            :disabled="disabled"
            :style="style"
            @keydown.enter="handleEnterKey"
        />
        <div class="far-right" v-if="innerSuffixLabel"><slot /></div>
        <p v-if="errors.length" class="help is-danger">{{ errors.first("search") }}</p>

        <div v-if="selectedItem.test || selectedItem.status" :class="rightStatusStyle" class="absolute flex right-1">
            <p v-if="selectedItem.test" class="text-[10px] bg-blue-300 text-white py-[2px] px-2 rounded mr-1">TEST</p>
            <p class="text-[10px] bg-blue-300 text-white py-[2px] px-2 rounded">{{ selectedItem.status }}</p>
        </div>

        <transition name="grow">
            <div ref="dropdown" class="results-container overflow-visible" :style="{ width: dropDownWidth }" v-if="suggestions.length != 0">
                <div
                    v-for="(resultType, key) in suggestions"
                    @mousedown="getClientDetails(resultType)"
                    :key="key"
                    ref="dropdownOption"
                    class="relative h-6 flex items-center justify-between hover:bg-gray-100"
                >
                    <p class="px-2 py-1 text-sm" :class="{ 'bg-gray-100': key === selectedIndex }" v-html="resultType.html" />
                    <div class="flex space-x-2">
                        <p v-if="resultType.test" class="text-[10px] bg-blue-300 text-white py-[2px] px-2 rounded">TEST</p>
                        <p v-if="resultType.status" class="text-[10px] bg-blue-300 text-white py-[2px] px-2 rounded whitespace-nowrap">{{ resultType.status }}</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
            text: "",
            name: "",
            focus: false,
            autocompleteDebounce: undefined,
            suggestions: [],
            selectedClientId: undefined,
            selectedIndex: 0,
            selectedItem: {
                status: null,
                test: false,
            },
        };
    },
    props: {
        options: {
            type: Array,
        },
        placeholder: {
            type: String,
        },
        selected: {
            type: Object,
        },
        required: {
            type: Boolean,
            default: false,
        },
        unStyled: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        innerSuffixLabel: {
            type: Boolean,
            default: false,
        },
        dropDownWidth: {
            type: String,
            default: "135%",
        },
        size: {
            type: String,
            default: "small", //small, medium
        },
    },
    mounted() {
        this.$watch(propValue => {
            this.init(propValue.selected);
        });
    },
    methods: {
        init(selectedValue) {
            const currentOption = this.options.find(option => option.value === selectedValue?.value);
            this.$refs.searchInput.value = currentOption?.name || "";
            this.selectedItem.name = currentOption?.nameShort || "";
            this.selectedItem.status = currentOption?.status || "";
            this.selectedItem.test = currentOption?.test || "";
        },
        handleFocus() {
            this.focus = true;
            this.selectedIndex = 0;
            this.$refs.searchInput.select();
            window.addEventListener("keydown", this.traverseOptions);
        },
        handleBlur() {
            this.focus = false;
            this.suggestions = [];
            this.selectedIndex = 0;
            this.$refs.searchInput.blur();
            window.removeEventListener("keydown", this.traverseOptions);
        },
        handleEnterKey() {
            this.$emit("enterKeyPressed");
        },
        triggerBlur() {
            this.handleBlur();
        },
        traverseOptions(e) {
            switch (e.keyCode) {
                case 27: // esc
                    e.preventDefault();
                    this.triggerBlur();
                    break;
                case 13: // enter
                    e.preventDefault();
                    this.getClientDetails(this.suggestions[this.selectedIndex]);
                    this.handleBlur();
                    break;
                case 9: // tab
                    e.preventDefault();
                    this.getClientDetails(this.suggestions[this.selectedIndex]);
                    this.handleBlur();
                    break;
                case 40: // down arrow
                    e.preventDefault();
                    this.traverseDown();
                    break;
                case 38: // up arrow
                    e.preventDefault();
                    this.traverseUp();
                    break;
                default:
                    break;
            }
        },
        traverseDown() {
            const length = this.suggestions.length;
            if (this.selectedIndex < length - 1) this.selectedIndex++;
            this.scrollToElement(this.$refs.dropdownOption[this.selectedIndex]);
        },
        traverseUp() {
            if (this.selectedIndex > 0) this.selectedIndex--;
            this.scrollToElement(this.$refs.dropdownOption[this.selectedIndex]);
        },
        scrollToElement(element) {
            const dropdown = this.$refs.dropdown;
            const scrollTop = dropdown.scrollTop;
            const scrollBounds = {
                top: scrollTop,
                bottom: scrollTop + dropdown.clientHeight,
            };
            const elementBounds = {
                top: element.offsetTop,
                bottom: element.offsetTop + element.clientHeight,
            };
            if (elementBounds.top < scrollBounds.top) {
                dropdown.scrollTop = elementBounds.top;
            } else if (elementBounds.bottom > scrollBounds.bottom) {
                dropdown.scrollTop = elementBounds.bottom - dropdown.clientHeight;
            }
        },
        autocomplete(event) {
            this.text = event.target.value;
            if (this.text == "") {
                this.$emit("on-selected", this.text);
            }
            const regexp = new RegExp(`${this.text}`, "iu");
            this.suggestions = this.options.filter(suggestion => {
                return suggestion.name.match(regexp) || suggestion.status?.match(regexp);
            });
            this.suggestions.forEach(element => {
                const found = element.name.match(regexp);
                if (!found) return;
                element.html = element.name.replace(regexp, "<b>" + found[0] + "</b>");
            });
            this.selectedIndex = 0;
        },
        getClientDetails(result) {
            if (!result) {
                this.$refs.searchInput.value = "";
                this.$nextTick(() => {
                    this.$refs.searchInput.select();
                });
                return;
            }
            this.$nextTick(() => {
                if (this.$refs.searchInput) this.$refs.searchInput.value = result.name;
            });
            this.selectedItem.name = result?.nameShort || "";
            this.selectedItem.status = result?.status || "";
            this.selectedItem.test = result?.test || "";
            this.$emit("on-selected", result);
        },
    },
    computed: {
        rightStatusStyle() {
            if (this.size === "medium") return "top-2";
            else return "top-[5px]";
        },
        style() {
            if (this.size === "medium") return "height: 37px";
            else return "";
        },
    },
};
</script>
<style scoped>
.far-right {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
.grow-enter-active,
.grow-leave-active {
    transition: max-height 0.3s, padding 0.2s, opacity 0.1s;
}
.grow-enter,
.grow-leave-to {
    max-height: 0;
    padding: 0;
    opacity: 0;
}
.results-container {
    cursor: pointer;
    max-height: 250px;
    overflow-y: auto;
    font-weight: 400;
    padding: 20px;
    z-index: 10000;
    padding-top: 0;
    margin-left: 100px;
    position: absolute;
    top: 40px;
    left: -100px;
    background: white;
    border: solid 1px #cadeea;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
}
.results-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.results-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
}
</style>
